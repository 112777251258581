import React from "react";
import Navbar from "../Components/Navbar/Nav";
import Footer from "../Components/Footer/Footer";
import Section5 from "../Components/section5/Component";



function Home() {
  return (
   <>
    <Navbar/>
     <Section5 />
    <Footer/>
   
   </>
  );
}

export default Home;
