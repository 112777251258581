import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css'; // Import CSS file for styling

const Footer = () => {
  const containerStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '10px',
    zIndex: 6,
    left: 'initial',
  };
  return (
    <>
    <div className="container-fluid bck-clr">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6">
          <img src="img/logo.png" className="img-fluid logo_img" alt="CodesAndNotes Logo" />
        </div>
        {/* <div className="col-1 dis"></div> */}
        <div className="col-lg-2 col-md-6 col-sm-6">
          <h1 className='head-ingtop'>Technology</h1>
          <p className="footer_tabs"><Link className='footer_tabs' to="/">HTML, CSS & Bootstrap</Link></p>
          <p className="footer_tabs"><Link className='footer_tabs' to="/">JavaScript & JQuery</Link></p>
          <p className="footer_tabs"><Link className='footer_tabs' to="/">React JS</Link></p>
          <p className="footer_tabs"><Link className='footer_tabs' to="/">Node JS</Link></p>
          <p className="footer_tabs"><Link className='footer_tabs' to="/">Express JS</Link></p>
          <p className="footer_tabs"><Link className='footer_tabs' to="/">MongoDB</Link></p>
          <p className="footer_tabs"><Link className='footer_tabs' to="/">Python</Link></p>
          <p className="footer_tabs"><Link className='footer_tabs' to="/">PHP</Link></p>



        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 myf_top">
        <h1 className='head-ingtop'>Services</h1>
          <p className="footer_tabs"><Link className="footer_tabs" to="/">Offline Training</Link></p>
          <p className="footer_tabs"><Link className="footer_tabs" to="/">Online Training</Link></p>
          <p className="footer_tabs"><Link className="footer_tabs" to="/">Certificates</Link></p>
          <p className="footer_tabs"><Link className="footer_tabs" to="/">Live Projects</Link></p>
          <p className="footer_tabs"><Link className="footer_tabs" to="/">Doubt Classes</Link></p>
          <p className="footer_tabs"><Link className="footer_tabs" to="/">Internship</Link></p>
          <p className="footer_tabs"><Link className="footer_tabs" to="/Privacy">Privacy</Link></p>


        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 myf_top">
          <div className="footer-flx">
            <div>
            <i className="ri-global-line"></i>
            </div>
            <div className="ftr-txt-top">
            <p className="address">Plot No. 5B, Sector - 15A, First Floor, Neelam Ajronda Chowk Metro Station, Faridabad, Haryana 121007</p>
            </div>
          </div>
          <div className="footer-flx">
            <div>
            <i className="ri-mail-fill"></i>
            </div>
            <div className="ftr-txt-top">
                <p className="address">codesandnotes01@gmail.com</p>
            </div>
          </div>
          <div className="footer-flx">
            <div>
            <i className="ri-phone-fill"></i>
            </div>
            <div className="ftr-txt-top">
            <p className="address">+91-9354705402</p>
            </div>
          </div>
        </div>
      </div>
    </div>
 
    <div className="container-fluid bck-copy-right">
      <div className="row ">
        <div className="col-sm-12">
          <p className="copy_right">Copyright © 2024 CodesAndNotes. All Rights Reserved.</p>
        </div>
      </div>
    </div>
    <div className="fixed-bottom" style={containerStyle}>
      <a href="https://wa.me/918130501014" target="_blank" rel="noopener noreferrer">
        <img src="img/whats-app.png"className="img-fluid" width="60" alt="CodesAndNotes" />
      </a>
    </div>
    </>

  );
};

export default Footer;
