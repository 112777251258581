import React from 'react';

const CourseCard = ({ course, onKnowMoreClick }) => (
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
        <div className="course-crd">
            <div className="crd-headder">
                <div className="ic-crcl">
                    <img src={course.image} alt={course.image} className="img-responsive price-icon" />
                </div>
                <div>
                    <p className="hedr-txt">{course.title}</p>
                    <p className="sub-hedr-txt">( {course.duration} )</p>
                </div>
            </div>
            <p className="tech-heading">{course.sub_title}</p>
            <div className="tec-part">
                {course.module_key.map((tech, index) => (
                    <div key={index} className="name-btn">{tech}</div>
                ))}
            </div>
            <div className="btn-flx">
                <button className="course-btn" onClick={onKnowMoreClick}>{course.know_more}</button>
            </div>
        </div>
    </div>
);

export default CourseCard;
