import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './nav.css'; // Import CSS file for styling

const Navbar = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 991);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDropdownHover = (e) => {
    if (isDesktop) {
      const dropdownMenu = e.currentTarget.querySelector('.sm-menu');
      dropdownMenu.style.display = 'block';
    }
  };

  const handleDropdownLeave = (e) => {
    if (isDesktop) {
      const dropdownMenu = e.currentTarget.querySelector('.sm-menu');
      dropdownMenu.style.display = 'none';
    }
  };

  return (
    <>

      {/* <nav className="navbar navbar-expand-lg navbar-light shadow-sm bg-light fixed-top">
      <div className="container">
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <img src="img/logo.png" className="img-fluid logo_navbar" alt="Hyper Logo" />
          {/* <span className="ml-3 font-weight-bold">BRAND</span> */}
      {/* </Link>
        <button
          className="navbar-toggler navbar-toggler-right border-0"
          type="button"
          data-toggle="collapse"
          data-target="#navbar4"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbar4">
          <ul className="navbar-nav mr-auto pl-lg-4">
            
          </ul>
          <ul className="navbar-nav ml-auto mt-3 mt-lg-0 pl-lg-4">
            <button className="btn-enr-oll">Enroll Now</button>
          </ul>
        </div>
      </div>
    </nav> */}
      <div className="container">
        <div className='top_flax_nav'>
          <div className='top_nav1_logo'>
            <img src="img/logo.png" className="img-fluid logo_navbar" alt="Hyper Logo" />
          </div>
          <div className='top_nav1_bot'>
          <Link className="footer_tabs" to="/contact"><button className="btn-enr-oll">Enroll Now</button></Link>
          </div>

        </div>
      </div>
    </>
  );
};

export default Navbar;
