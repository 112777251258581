import React from 'react';
import './component.css';

const Traning = () => {
  return (
   <div className="container-fluid crd2-bck">
       <div className="row">
        <div className="col-lg-4 col-md-6  col-sm-6">
            <div className="simpel-crd">
            <center><img src="img/employee.png"alt="CodesAndNotes.co.in"className="img-fluid scnd-crd-icon"></img></center>
                <p className="crd-main-heading">Earn Your First Job</p>
                <p className="crd-sub-heading">With CodesAndNotes real-time training and advanced certifications be ready to earn your first job as a digital practitioner.</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6  col-sm-6">
            <div className="simpel-crd">
                <center><img src="img/diagram.png"alt="CodesAndNotes.co.in"className="img-fluid scnd-crd-icon"></img></center>
                <p className="crd-main-heading">Expand Your Business</p>
                <p className="crd-sub-heading">Reach thousands of potential customers online and expand your business exponentially without spending a lot.</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6  col-sm-6">
            <div className="simpel-crd">
            <center><img src="img/multitasking.png"alt="CodesAndNotes.co.in"className="img-fluid scnd-crd-icon"></img></center>
                <p className="crd-main-heading">Upgrade Your Skills</p>
                <p className="crd-sub-heading">Add Web Development to your skillset & boost your chances to get a better
pay-out in the industry.</p>
            </div>
        </div>
       </div>
   </div>
  );
};

export default Traning;
