import React, { useState } from 'react';
import './component.css';

const Training = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    city: '',
    classType: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const formattedDate = currentDate.getFullYear() + '-' +
      ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
      ('0' + currentDate.getDate()).slice(-2);

    // const object = {
    //   name: formData.name,
    //   email: formData.email,
    //   mobile: formData.mobile,
    //   city: formData.city,
    //   classType: formData.classType,
    //   date: formattedDate
    // };
    const object = {
      to: "hypertonicitsolutions@gmail.com",
      from: formData.email,
      subject: 'New Requests',
      body: `
          <html>
            <head>
                <meta charset="UTF-8">
            </head>
            <body style="font-family: Arial, sans-serif; margin: 0; padding: 20px; background-color: #f4f4f4; width:100%;">
                <div style="width: 100%; max-width: 800px; margin: auto; border: 1px solid #ddd; border-radius: 8px; padding: 20px; background: white; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
                    <h2 style="text-align: center; color: #333;">New Inquiry Details</h2>
                    <table style="width: 100%; border-collapse: collapse; margin-top: 20px;">
                        <tr>
                            <th style="border: 1px solid #ddd; padding: 12px; background-color: #f2f2f2; color: #555;">Name</th>
                            <th style="border: 1px solid #ddd; padding: 12px; background-color: #f2f2f2; color: #555;">Mobile</th>
                            <th style="border: 1px solid #ddd; padding: 12px; background-color: #f2f2f2; color: #555;">City</th>
                            <th style="border: 1px solid #ddd; padding: 12px; background-color: #f2f2f2; color: #555;">Class Type</th>
                            <th style="border: 1px solid #ddd; padding: 12px; background-color: #f2f2f2; color: #555;">Date</th>
                        </tr>
                        <tr style="background-color: #f9f9f9;">
                            <td style="border: 1px solid #ddd; padding: 12px;">${formData.name}</td>
                            <td style="border: 1px solid #ddd; padding: 12px;">${formData.mobile}</td>
                            <td style="border: 1px solid #ddd; padding: 12px;">${formData.city}</td>
                            <td style="border: 1px solid #ddd; padding: 12px;">${formData.classType}</td>
                            <td style="border: 1px solid #ddd; padding: 12px;">${formattedDate}</td>
                        </tr>
                    </table>
                    <div style="text-align: center; margin-top: 20px; color: #888; font-size: 0.9em;">
                        Thank you for your inquiry!
                    </div>
                </div>
            </body>
            </html>
      `,
    };


    // try {
    //   await fetch('https://hypertonic.co/mail/api/advance/v1/index.php', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'X-Custom-Token': 'G7h384$}U*&n3459tu3h6'
    //     },
    //     body: JSON.stringify(object),
    //   });
    //   alert("Thanks for connecting with us");
    // } catch (error) {
    //   console.error(error);
    // }
    try {
      const response = await fetch('https://hypertonic.co/mail/api/advance/v1/index.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Custom-Token': 'G7h384$}U*&n3459tu3h6'
        },
        body: JSON.stringify(object),
      });

      if (!response.ok) {
        const errorData = await response.json(); // Get the error message from response
        throw new Error(`Error: ${errorData.message}`);
      }

      const data = await response.json(); // Assuming response is JSON
      console.log(data); // Log the success response
      alert("Thanks for connecting with us");
    } catch (error) {
      console.error('Error:', error.message); // Log any error
      alert("Something went wrong. Please try again.");
    }

    setFormData({
      name: '',
      email: '',
      mobile: '',
      city: '',
      classType: ''
    });
  };

  return (
    <div className="container-fluid traning_back_img">
      {/* <Helmet>
        <title>Ranked #1 Web Development Course in Faridabad</title>
        <meta name="description" content="Unlock your future with expert training in full-stack web, application, and software development courses." />
      </Helmet> */}
      <div className="row">
        <div className="overlay_theme"></div>
        <div className="col-lg-8   frm_bck1">
          <h1 className="no-heading">Ranked #1 Web Development Course in Faridabad</h1>
          <p className="traning_heading">
            Unlock your future with expert training in <span className="traning_heading-clr">full-stack web, application, and software development </span>courses.
          </p>
          <button className="download-brocher">Download Brochure</button>
        </div>
        <div className="col-lg-4  frm_bck1">
          <div className="frm_bck">
            <p className="frm-txt">
              SIGN UP FOR FREE DEMO CLASS
            </p>
            <form onSubmit={handleSubmit}>
              <label className="lab-eel" htmlFor="name">Name*</label><br />
              <input
                type="text"
                placeholder="Enter Your Name"
                name="name"
                className="frm-field"
                value={formData.name}
                onChange={handleChange}
                required
              /><br />
              <label className="lab-eel" htmlFor="email">Email*</label><br />
              <input
                type="email"
                placeholder="Enter Your Email"
                name="email"
                className="frm-field"
                value={formData.email}
                onChange={handleChange}
                required
              /><br />
              <label className="lab-eel" htmlFor="mobile">Mobile No*</label><br />
              <input
                type="number"
                placeholder="Enter Your Mobile No"
                name="mobile"
                className="frm-field"
                value={formData.mobile}
                onChange={handleChange}
                required
              /><br />
              <label className="lab-eel" htmlFor="city">City*</label><br />
              <input
                type="text"
                placeholder="Enter Your City"
                name="city"
                className="frm-field"
                value={formData.city}
                onChange={handleChange}
                required
              /><br />
              <label className="lab-eel" htmlFor="classType">Class Type*</label><br />
              <select
                className="frm-field"
                name="classType"
                value={formData.classType}
                onChange={handleChange}
                required
              >
                <option disabled value="">--Select--</option>
                <option value="Online">Online</option>
                <option value="Offline">Offline</option>
              </select><br />
              <center>
                <button type="submit" className="skd_btn" id="submit_btn">Submit</button>
              </center>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Training;
